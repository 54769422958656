import { useRef } from 'react';
import { Menu } from 'antd'

import { useOutsideClick } from './utils/hooks/handleOutsideClick';
import './ContextMenu.less';


const ContextMenu = ({ show, onClose, onRemove }) => {
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, onClose);

  const handleRemove = (e) => {
    onRemove();
    onClose();
  }

  if (!show) {
    return null;
  }

  return (
    <div id="chart-context-menu" ref={wrapperRef}>
      <Menu>
        <Menu.Item onClick={handleRemove} key="remove">Remove</Menu.Item>
        <Menu.Item onClick={onClose} key="close">Close</Menu.Item>
      </Menu>
    </div>
  )
}

export default ContextMenu;