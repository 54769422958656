import FileSaver from "file-saver";
import JSZip from "jszip";
import * as utils from '../utils';

import { getIncludedExcludedSources } from "./filter";

export const draw = (chart, state) => {
  if (!chart) {
    return;
  }
  const { formValues, articles } = state;
  const { sourceReach, displayArticles } = formValues;
  let includedArticles;
  const { includedSources } = getIncludedExcludedSources(state, false);
  const includedSourceNames = includedSources.map((source) => source.moniker_name);

  // Article select
  if (!displayArticles) {
    includedArticles = [];
  }
  else {
    includedArticles = articles
    .filter(
      (article) => includedSourceNames.includes(article.moniker_name)
    );
  }
  chart.update(includedArticles, includedSources, sourceReach, displayArticles);
}

export const exportFilteredSources = (state) => {
  const { articles } = state;

  let includedSourcesName, excludedSourcesName;
  let includedContentPieces, excludedContentPieces;
  let { includedSources, excludedSources } = getIncludedExcludedSources(state, true);
  includedSourcesName  = includedSources
  .map(
    (source) => source.moniker_name
  );

  excludedSourcesName = excludedSources
  .map(
    (source) => source.moniker_name
  );

  includedContentPieces = articles
  .filter(
    (article) => includedSourcesName.includes(article.moniker_name)
  );

  excludedContentPieces = articles
  .filter(
    (article) => excludedSourcesName.includes(article.moniker_name)
  );


  // create final list of required columns for source csv
  includedSources = includedSources
    .map(({moniker_name, domain, bias_mean, reliability_mean, bias_label,reliability_label, mediatype, coverage, location}) =>
    `\n"${moniker_name}",${domain},${bias_mean.toFixed(2)},${reliability_mean.toFixed(2)},${bias_label},${reliability_label},${utils.getKeyByValue(utils.mediaName,mediatype)},${coverage},${location.country.name},${location.state.name}`
    );

  excludedSources = excludedSources
    .map(({moniker_name, domain, bias_mean, reliability_mean, bias_label,reliability_label, mediatype, coverage, location}) =>
    `\n"${moniker_name}",${domain},${bias_mean.toFixed(2)},${reliability_mean.toFixed(2)},${bias_label},${reliability_label},${utils.getKeyByValue(utils.mediaName,mediatype)},${coverage},${location.country.name},${location.state.name}`);

    includedContentPieces = includedContentPieces.map(({moniker_name, url, bias, reliability}) => {
      return `\n"${moniker_name}","${url}",${bias},${reliability}`;
    });
    excludedContentPieces = excludedContentPieces.map(({moniker_name, url, bias, reliability}) => {
      return `\n"${moniker_name}","${url}",${bias},${reliability}`;
    });

    // add content heading
    includedContentPieces.unshift("source,url,bias,reliability");
    excludedContentPieces.unshift("source,url,bias,reliability");

    includedSources.unshift("source,domain,bias,reliability,bias label,reliability label,media type,location,country,state");
    excludedSources.unshift("source,domain,bias,reliability,bias label,reliability label,media type,location,country,state");
    
    // create blob of csv from arrays
    const includedSourcesBlob = new Blob([includedSources], {type: 'text/csv'});
    const excludedSourcesBlob = new Blob([excludedSources], {type: 'text/csv'});
    const includedContentPiecesBlob = new Blob([includedContentPieces], {type: 'text/csv'});
    const excludedContentPiecesBlob = new Blob([excludedContentPieces], {type: 'text/csv'});

    // zip all blobs into single zip blob
    let zip = new JSZip();
    zip.file("Included_Sources.csv",includedSourcesBlob);
    zip.file("Excluded_Sources.csv",excludedSourcesBlob);
    zip.file("Included_Content_Pieces.csv",includedContentPiecesBlob);
    zip.file("Excluded_Content_Pieces.csv",excludedContentPiecesBlob);

    zip.generateAsync({type: "blob"}).then((content) => {
      FileSaver.saveAs(content, `Chart_Data_Export_${new Date().toString().replace(/ /gi, "_")}.zip`);
    })
}