import React from "react";
import { Form, Button, Select, Radio, Switch, Slider, Drawer, Col, Row, Checkbox, Tooltip} from "antd";
import { CloseOutlined, CheckOutlined, FilterOutlined, UndoOutlined } from "@ant-design/icons";
import { mediaType, enableValues, locationOptions, dynamicSort, unlimitedSearchParameter } from "./utils";
import "./ControlsForm.less";
import { canAccess, needReset } from "./searchControl.js";

const { Option } = Select;
class ControlsForm extends React.Component {

  constructor(props) {
    super(props);

    this.state ={
      visible: false,
      defaultFormValues: {
        allSources: true,
        displayArticles: true,
        sources: [],
        location: null,
        countrySelection: false,
        stateSelection: false,
        locationCountry: [],
        locationState:[],
        rangeReliability: [0, 64],
        rangeBias: [-42, 42],
        mediaType: mediaType,
        ownership: null,
      }
    }
  }

  componentDidMount(){
    needReset();
  }

  formRef = React.createRef();
  
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onSourcesChange = (value) => {
    if (value) {
      this.formRef.current.setFieldsValue({
        allSources: false,
        displayArticles: true
      });
      
      if (!unlimitedSearchParameter()){
        let [tf, slist] = canAccess(value,"free");
        this.formRef.current.setFieldsValue({
          sources: slist,
        });
        if(!tf){
          this.props.setModalVisible("general_modal",true);
        }
      }
    }
    this.onFinish();
  };

  onLocationChange = ({ target: { value } }) => {
    if (value) {
      this.formRef.current.setFieldsValue({
        allSources: false,
        displayArticles: true,
        ownership: null
      });
    }

    if (value === "International"){
      this.setState({countrySelection: true, stateSelection: false});
      this.formRef.current.setFieldsValue({locationState: []});
    }
    else if (value === "Local"){
      this.setState({countrySelection: false, stateSelection: true});
      this.formRef.current.setFieldsValue({locationCountry: []});
    }
    else {
      this.setState({countrySelection: false, stateSelection: false});
      this.formRef.current.setFieldsValue({locationCountry: [], locationState: []});
    }

    this.onFinish();
  };

  locationCountryChange = () => this.onFinish();

  locationStateChange = () => this.onFinish();

  ownershipChange = (value) => {
    if (value) {
      this.formRef.current.setFieldsValue({
        allSources: false,
        displayArticles: true,
        location: null,
        locationCountry: [],
        locationState: []
      });
      this.setState({countrySelection: false, stateSelection: false});
    }
    this.onFinish();
  };

  onFinish = () => {
    this.props.formSubmit(this.formRef.current.getFieldsValue());
  };

  onReset = () => {
    this.setState({countrySelection: false, stateSelection: false, citySelection: false});
    this.formRef.current.resetFields();
    this.props.chartReset();
    this.onFinish();
  };

  componentDidUpdate(prevProps) {
    if(prevProps.removedSources.length !== this.props.removedSources.length
      || prevProps.sources.length !== this.props.sources.length
      ) {
      this.formRef.current.setFieldsValue({
        sources: this.formRef.current.getFieldValue("sources").filter(
          (source) => !this.props.removedSources.find(
            removedSource => removedSource.moniker_name === source
          )
        )
      })
      this.onFinish();
    }
  }

  render() {

    const { sources, countries, states, chartLoading, ownership } = this.props;

    var mediaTypeOptions = [
      {label: 'Website / Articles', value: 2, disabled: !enableValues[1]},
      {label: 'TV / Video', value: 3, disabled: !enableValues[2]},
      {label: 'Podcast / Audio', value: 4, disabled: !enableValues[3]},
    ];

    let formSources = [];

    if (this.formRef.current) {
      formSources = this.formRef.current.getFieldValue("sources");
    }

    return (
      <Form
        ref={this.formRef}
        name="control-ref"
        layout="horizontal"
        className="formControls"
        initialValues={this.state.defaultFormValues}
      >
        <Form.Item
              name="sources"
              colon={false}
              className="sourcesArea verticalField"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Search sources"
                disabled={!chartLoading}
                listHeight={500}
                onChange={this.onSourcesChange}
                allowClear
              >
                {sources
                  .filter((o) => !formSources.includes(o.moniker_name))
                  .filter(
                    (source) => !this.props.removedSources.find(
                      removedSource => removedSource.moniker_name === source.moniker_name
                    )
                  )
                  .sort(dynamicSort("moniker_name"))
                  .map((source) => (
                    <Option key={source.moniker_name} value={source.moniker_name}>
                      {source.moniker_name}
                    </Option>
                  ))
                }
              </Select>
        </Form.Item>

        <Form.Item
          name="mediaType"
          className="mediatypeArea verticalField"
        >
          <Checkbox.Group
            name="mediabox"
            options={mediaTypeOptions}
            onChange={this.onFinish}
            disabled={!chartLoading}
          >
          </Checkbox.Group>
        </Form.Item>

        <Form.Item
          name="rangeReliability"
          label="Filter source reliability range"
          className="rangeReliabilityArea verticalField"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Slider
            range
            marks={{ 0: "0", 64: "64" }}
            min={0}
            max={64}
            onChange={this.onFinish}
            disabled={!chartLoading}
          />
        </Form.Item>

        <Form.Item
          name="rangeBias"
          label="Filter source bias range"
          className="rangeBiasArea verticalField"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Slider
            range
            marks={{ "-42": "-42", 0: "0", 42: "42" }}
            min={-42}
            max={42}
            onChange={this.onFinish}
            disabled={!chartLoading}
          />
        </Form.Item>

        <Tooltip
          title="Customize Chart as You Like"
          color="geekblue"
          placement="right"
        >
          <Button
          size="large"
          onClick={this.showDrawer}
          className="filterButton moreFilterArea"
          disabled={!chartLoading}
          >
            <FilterOutlined />Filters
          </Button>
        </Tooltip>

        <Button
        htmlType="button"
        size="large"
        onClick={this.onReset}
        className="resetButton resetButtonArea"
        disabled={!chartLoading}
        >
          <UndoOutlined /> Reset
        </Button>

        <Drawer
          title="Customize Chart"
          width={300}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
          name="drawer"
          forceRender="true"
        >
          <Row gutter={32}>
              <Col span={24}>
                <Form.Item
                name="displayArticles"
                label="Display content piece data"
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onChange={this.onFinish}
                />
              </Form.Item>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={24}>
                <Form.Item
                label="Location"
                name="location"
                >
                  <Radio.Group
                    options={locationOptions}
                    onChange={this.onLocationChange}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>
              </Col>
              {this.state.countrySelection ? 
              <Col span={24}>
                <Form.Item
                name="locationCountry">
                  <Select
                    mode="multiple"
                    placeholder="select country"
                    listHeight={500}
                    onChange={this.locationCountryChange}
                    allowClear
                    disabled={!chartLoading}
                  >
                    { countries ? 
                      Object.entries(countries).map(([key,value]) => (
                        <Option key={key} value={value}>
                          {value}
                        </Option>
                      ))
                      : <Option></Option>
                    }
                  </Select>
                </Form.Item>
              </Col>
              : null }
              {this.state.stateSelection ? 
              <Col span={24}>
                <Form.Item
                name="locationState">
                  <Select
                    mode="multiple"
                    placeholder="select state"
                    listHeight={500}
                    onChange={this.locationStateChange}
                    allowClear
                    disabled={!chartLoading}
                  >
                    { states ? 
                      Object.entries(states).map(([key,value]) => (
                        <Option key={key} value={value}>
                          {value}
                        </Option>
                      ))
                      : <Option></Option>
                    }
                  </Select>
                </Form.Item>
              </Col>
              : null }
            </Row>
            <Row gutter={35}>
              <Col span={24}>
                <Form.Item
                name="ownership"
                label="Ownership">
                  <Select
                  showSearch
                  placeholder="search by ownership"
                  onChange={this.ownershipChange}
                  style={{width:"250px"}}
                  allowClear
                  disabled={!chartLoading}>
                    { ownership ? 
                    Object.entries(ownership).map(([key,value]) => (
                      <Option key={key} value={value}>
                        {value}
                      </Option>
                    ))
                    : <Option></Option>
                    }
                  </Select>
                </Form.Item>
              </Col>
            </Row>
        </Drawer>
      </Form>
    );
  }
}

export default ControlsForm;
