import queryString from 'query-string';

var mediaType = [2,3,4];
var enableValues = [0,1,1,1];

var mediaName = {
  "Website / Articles": 2,
  "TV / Video": 3,
  "Podcast / Audio": 4
}

const locationOptions = [
  {
    label: 'Local',
    value: 'Local',
  },
  {
    label: 'National',
    value: 'National',
  },
  {
    label: 'International',
    value: 'International',
  },
];

function aamMember(){
  let member_id = queryString.parse(window.location.search)['member_id'];
  return member_id;
}

function downloadParameter(){
  if(queryString.parse(window.location.search)['download'] === "1"){
    return true;
  }
  return false;
}

function unlimitedSearchParameter(){
  if(queryString.parse(window.location.search)['unlimitedSearch'] === "1"){
    return true;
  }
  return false;
}

function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

function dynamicSort(property) {
  var sortOrder = 1;

  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }

  return function (a,b) {
    if(sortOrder === -1){
      return b[property].localeCompare(a[property]);
    }
    else{
      return a[property].localeCompare(b[property]);
    }
  }
}

function dict2array(dict) {
  return Object.keys(dict).map((key) => dict[key]);
}

function availableStates(countryList, sources){
  if (countryList) {
    // let countryids = countryList.map(Number);
    let cleanSources = sources.filter((source) => countryList.includes(source.location.country.name));
    let filteredStates = {};
    cleanSources.forEach((source) => {
      if (source['location']['state']['id']){
        if (getKeyByValue(filteredStates,source['location']['state']['name']) in filteredStates){}
        else{filteredStates[source['location']['state']['id']] = source['location']['state']['name']}
      }
    });
    return filteredStates;
  }
  else {
    return {}
  }
}

function availableCities(stateList, sources){
  if (stateList){
    // let stateids = stateList.map(Number);
    let cleanSources = sources.filter((source) => stateList.includes(source.location.state.name));
    let filteredCities = {};
    cleanSources.forEach((source) => {
      if (source['location']['city']['id']){
        if(getKeyByValue(filteredCities,source['location']['city']['name']) in filteredCities){}
        else{filteredCities[source['location']['city']['id']] = source['location']['city']['name']}
      }
    });
    return filteredCities;
  }
  else {
    return {}
  }
}

export { aamMember, downloadParameter, unlimitedSearchParameter, mediaName, mediaType, enableValues, locationOptions, getKeyByValue, dynamicSort, dict2array, availableStates, availableCities };