import * as d3 from "d3";
import { dict2array, getKeyByValue } from "./utils";

const sourceToExclude = [];

function reliabilityWeight(reliability) {
    if (reliability >= 24) {
      return 1;
    } else if (reliability >= 16) {
      return 4;
    } else if (reliability >= 8) {
      return 8;
    } else {
      return 16;
    }
  }
  
  function biasWeight(bias) {
    if (-6 <= bias && bias <= 6) {
      return 1;
    } else if (-18 <= bias && bias <= 18) {
      return 4;
    } else if (-30 <= bias && bias <= 30) {
      return 8;
    } else {
      return 16;
    }
  }

  function biasLabel(bias){
    if(-6 <= bias && bias <= 6){
      return "Middle";
    } else if (-18 <= bias && bias < -6){
      return "Skews Left";
    } else if (-30 <= bias && bias < -18){
      return "Hyper-Partisan Left";
    } else if (-42 <= bias && bias < -30){
      return "Most Extreme Left";
    } else if (6 < bias && bias < 18){
      return "Skews Right";
    } else if (18 <= bias && bias < 30){
      return "Hyper-Partisan Right";
    } else if (30 <= bias && bias < 42){
      return "Most Extreme Right";
    } else {
      return null;
    }
  }

  function reliabilityLabel(reliability){
    if(0 <= reliability && reliability <= 8){
      return "Unreliable; Inaccurate";
    } else if (8 < reliability && reliability <= 16){
      return "Unreliable; Misleading";
    } else if (16 < reliability && reliability <= 24){
      return "Unreliable; Problematic";
    } else if (24 < reliability && reliability <= 32){
      return "Mixed Reliability/Opinion OR Other Issues";
    } else if (32 < reliability && reliability <= 40){
      return "Generally Reliable/Analysis OR Other Issues";
    } else if (40 < reliability && reliability <= 48){
      return "Reliable; Analysis/Fact Reporting";
    } else if (48 < reliability && reliability <= 56){
      return "Reliable; Fact Reporting";
    } else if (reliability > 56) {
      return "Reliable; Original Fact Reporting";
    } else {
      return null;
    }
  }
  
  function cleanArticles(articles) {
    var sources = {};
    
    articles = articles.filter(
      (article) => !sourceToExclude.includes(article.moniker_name)
    );
  
    articles.forEach((record) => {
      let source = sources[record["moniker_name"]];
      if (!source) {
        source = {
          bias_total: 0,
          bias_count: 0,
          reliability_total: 0,
          reliability_count: 0,
        };
      }
  
      const wBias = biasWeight(record["bias"]);
      const wReliability = reliabilityWeight(record["reliability"]);
      let sourceLocation = {};
  
      source["domain"] = record["main_url"];
      source["about_url"] = record["about_url"];
      source["moniker_name"] = record["moniker_name"];
      source["image_path"] = record["image_path"];
      source["mediatype"] = record["mediatype"];
      sourceLocation["country"] = {'id': record["country_id"],'name': record["country_name"]};
      sourceLocation["state"] = {'id': record["state_id"],'name': record["state_name"]};
      sourceLocation["city"] = {'id': record["city_id"],'name': record["city_name"]};
      source["location"] = sourceLocation;
      source["ownership"] = dict2array(record["ownership"]);
      source["bias_total"] += record["bias"] * wBias;
      source["bias_count"] += wBias;
      source["reliability_total"] += record["reliability"] * wReliability;
      source["reliability_count"] += wReliability;
      sources[record["moniker_name"]] = source;
    });

    // create owners list from content pieces
    let ownership = {}
    articles.forEach((record) => {
      for ( let [key, value] of Object.entries(record['ownership']) ){
        if (!(key in ownership)) ownership[key] = value
      }
    });
  
    sources = Object.keys(sources).map((key) => {
      return {
        ...sources[key],
        bias_mean: sources[key]["bias_total"] / sources[key]["bias_count"],
        reliability_mean:
          sources[key]["reliability_total"] / sources[key]["reliability_count"],
      };
    });

    // supress sources and it's content-pieces if reliability is below 24
    let sourceBlockList = []; // prepare block list
    sources.forEach((source)=>{
      if (source.reliability_mean < 24){
        sourceBlockList.push(source.moniker_name);
      }
    });
    
    // apply that block list
    sources = sources.filter((source) => !sourceBlockList.includes(source.moniker_name));
    articles = articles.filter((article) => !sourceBlockList.includes(article.moniker_name));

    // add label based on score/value
    sources.forEach((source) => {
      source["bias_label"] = biasLabel(source["bias_mean"]);
      source["reliability_label"] = reliabilityLabel(source["reliability_mean"]);
    });

    
    // Calculate International, National and Local coverage for source
    sources.forEach((source) => {
      
      if(source['location']['country']['id'] !== 233){
        source['coverage'] = "International";
      }

      else if(source['location']['country']['id'] === 233 && (source['location']['state']['id'] === null) && (source['location']['city']['id'] === null) ){
        source['coverage'] = "National";
      }

      else if(source['location']['country']['id'] === 233 && (source['location']['state']['id'] !== null)){
        source['coverage'] = "Local";
      }
    });

    let countries = {};
    let states = {};
    let cities = {};
    let usa_states = {};

    sources.forEach(source => {
      if (source['location']['country']['id']){
        if (getKeyByValue(countries,source['location']['country']['name']) in countries){}
        else{countries[source['location']['country']['id']] = source['location']['country']['name']}
      }

      if (source['location']['state']['id']){
        if (getKeyByValue(states,source['location']['state']['name']) in states){}
        else{states[source['location']['state']['id']] = source['location']['state']['name']}
      }

      if (source['location']['state']['id'] && source['location']['country']['id'] === 233){
        if (getKeyByValue(usa_states,source['location']['state']['name']) in usa_states){}
        else{usa_states[source['location']['state']['id']] = source['location']['state']['name']}
      }

      if (source['location']['city']['id']){
        if (getKeyByValue(cities,source['location']['city']['name']) in cities){}
        else{cities[source['location']['city']['id']] = source['location']['city']['name']}
      }
    });
    
    // sort source list by reach value
    sources.sort((a, b) => a.reach - b.reach);
  
    return { articles, sources, countries, states, usa_states, cities, ownership };
  }
  
  function wrap(text, width) {
    text.each(function () {
      var text = d3.select(this),
        words = text.text().split(/\s+/).reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 15, // ems
        y = 0,
        dy = parseFloat(text.attr("dy")),
        tspan = text
          .text(null)
          .append("tspan")
          .attr("x", 0)
          .attr("y", y)
          .attr("dy", dy)
          .attr("dx", 0);
  
      while ((word = words.pop())) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", ++lineNumber * lineHeight + dy)
            .attr("dx", 0)
            .text(word);
        }
      }
  
      if (lineNumber > 0) {
        const xTranslate = text.node().transform.baseVal[0].matrix.e;
        const yTranslate = ((lineNumber - 1) * lineHeight) / 2;
        text.attr("transform", `translate(${xTranslate}, -${yTranslate})`);
      }
    });
  }
  
  function formatNumber(number) {
    let nums = number.replace(/,/g, "");
    if (!nums) return;
    return parseInt(nums).toLocaleString();
  }

  export { cleanArticles, wrap, formatNumber };