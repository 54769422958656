import * as utils from '../utils';

export const getIncludedExcludedSources = (state, withExcluded = false) => {

  const { formValues, sources } = state;
  const { rangeBias, rangeReliability, mediaType, locationCountry } = formValues;

  let defaultSources, selectedSources;
  let includedSources, excludedSources;


  if (mediaType?.length) {
    let filteredMedia = utils.mediaType.filter((type) => mediaType.includes(type));
    selectedSources = sources.filter((source) => filteredMedia.includes(source.mediatype));
  } else {
    selectedSources = [];
  }

  defaultSources = selectedSources;
  includedSources = defaultSources ;

  if (formValues.sources.length > 0) {
    includedSources = selectedSources
    .filter(
      (source) => formValues.sources.includes(source.moniker_name)
    );
  }

  if (formValues.ownership !== null){
    includedSources = selectedSources.filter((source) => source.ownership.includes(formValues.ownership));
  }

  if (formValues.location !== null) {
    includedSources = defaultSources.filter((source) =>
      formValues.location === source.coverage);
  }

  //filter country selection if available
  if (Array.isArray(locationCountry) && locationCountry.length && formValues.location === "International"){
    includedSources = defaultSources.filter((source) =>
      formValues.locationCountry.includes(source.location.country.name)
    );
  }

  //filter country selection if available
  if(Array.isArray(formValues.locationState) && formValues.locationState.length && formValues.location === "Local"){
    includedSources = defaultSources.filter((source) =>
      formValues.locationState.includes(source.location.state.name)
    );
  }

  if (
      (rangeReliability[0] !== 0 || rangeReliability[1] !== 64) ||
      (rangeBias[0] !== -42 || rangeBias[1] !== 42)
    ) {

    includedSources = includedSources
    .filter(
      (source) => source.reliability_mean >= rangeReliability[0] && 
                  source.reliability_mean <= rangeReliability[1]
    )
    .filter(
      (source) => source.bias_mean >= rangeBias[0] &&
                  source.bias_mean <= rangeBias[1]
    );
  }

  includedSources = includedSources
    .filter(
      (source) => !state.removedSources.find(
        removedSource => removedSource.moniker_name === source.moniker_name
    )
  )
  if(!withExcluded) {
    return { includedSources };
  }

  excludedSources = defaultSources
  .filter(
    (el) => !includedSources.includes(el)
  );
  return { includedSources, excludedSources };
}
