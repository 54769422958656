import moment from 'moment';
const SOURCE_DATA = 'source_data';
var defaultData = {
    'stamp'     : moment().format('YYYYMMDD'),
    'year'      : 0,
    'month'     : 0,
    'day'       : 0,
    'searches'  : []
}

// set defaultData if not found in localstorage
if(localStorage.getItem(SOURCE_DATA) === null){
    localStorage.setItem(SOURCE_DATA,JSON.stringify(defaultData));
}

// set old values from localstorage
var oldData = JSON.parse(localStorage.getItem(SOURCE_DATA));
var oldYear = oldData['year'];
var oldMonth = oldData['month'];
var oldDay = oldData['day'];
var oldSearches = oldData['searches'];

// get current timestamp when request is made
const currStamp = moment().format('YYYYMMDD');

// function to check whether user can view source list
export function canAccess(value,version){

    if(oldData['stamp'] === currStamp){

        var yearLimit, monthLimit, dayLimit, listLength;

        if (version === "free"){
            yearLimit = 50;
            monthLimit = 20;
            dayLimit = 5;
            listLength = false;
        }

        else if (version === "plus"){
            yearLimit = 100;
            monthLimit = 40;
            dayLimit = 15;
            listLength = true;
        }

        else if (version === "paid"){
            yearLimit = 500;
            monthLimit = 200;
            dayLimit = 75;
            listLength = true;
        }

        if (oldYear < yearLimit && oldMonth < monthLimit && oldDay < dayLimit && (oldSearches.length < 5 || listLength)){
            
            var slist;
            if (value.every(val => oldSearches.includes(val))){
                slist = oldSearches.filter(item => value.includes(item));
                defaultData['searches'] = oldSearches;
            }
            else {
                oldSearches = [...new Set([...oldSearches , ...value])];
                slist = value;
                defaultData['searches'] = oldSearches;
                oldDay += 1; oldMonth += 1; oldYear += 1;
            }

            defaultData['day'] = oldDay; defaultData['month'] = oldMonth; defaultData['year'] = oldYear;
            localStorage.setItem(SOURCE_DATA,JSON.stringify(defaultData));
            return [true, slist];
        }

        else {
            let slist = oldSearches.filter(item => value.includes(item));
            if (slist.length >= dayLimit || slist.length < value.length){
                return [false, slist];
            }
            else return [true, slist];
        }
    }

    else if(oldData['stamp'] < currStamp){
        return [false, value];
    }

    else {
        return [false, []]
    }
}

// function to reset timestamp with correct values in localStorage
export function needReset(){

    if (oldData['stamp'] < currStamp){
        if (currStamp.slice(0,4) > oldData['stamp'].slice(0,4)){
            oldData['stamp'] = currStamp;
            oldSearches = []; oldYear = 0; oldMonth = 0; oldDay= 0;
            localStorage.setItem(SOURCE_DATA,JSON.stringify(defaultData));
        }

        else if (currStamp.slice(4,6) > oldData['stamp'].slice(4,6)){
            oldData['stamp'] = currStamp;
            oldSearches = []; oldMonth = 0; oldDay= 0;
            defaultData['year'] = oldData['year'];
            localStorage.setItem(SOURCE_DATA,JSON.stringify(defaultData));
        }

        else if (currStamp.slice(6,8) > oldData['stamp'].slice(6,8)){
            oldData['stamp'] = currStamp;
            oldSearches = []; oldDay= 0;
            defaultData['month'] = oldData['month'];
            defaultData['year'] = oldData['year'];
            localStorage.setItem(SOURCE_DATA,JSON.stringify(defaultData));
        }
    }

    else if (oldData['stamp'] > currStamp){
        alert("This incident has been reported. Please pay for membership.");
    }
}