import React from "react";
import axios from "axios";
import HubspotForm from 'react-hubspot-form'
import * as d3 from "d3";
import * as canvg from "canvg";
import * as utils from "./utils";
import { cleanArticles } from "./calculation";
import "./App.less";
import { Chart, width, height } from "./chart";
import ControlsForm from "./ControlsForm";

import { mediaType } from "./utils";
import { Button, Tooltip, Spin, Modal } from "antd";
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import ContextMenu from "./ContextMenu";
import * as ChartUtils from './utils/chart';

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      dimensions: { top: 0, left: 0 },
      showGeneralModal: false,
      chartLoading: false,
      sources: [],
      articles: [],
      showContextMenu: false,
      selectedSource: null,
      removedSources: [],
      formValues: {
        allSources: true,
        displayArticles: true,
        sourceReach: false,
        sources: [],
        countries: [],
        states: [],
        rangeReliability: [0, 64],
        rangeBias: [-42, 42],
        rangeAudience:[0,268325000],
        mediaType: mediaType
      }
    };
  }

  callAPI = async () => {
    
    let aam_member_id = utils.aamMember();

    try {

      let API_ENDPOINT = "/api_aam/0/"
      if (aam_member_id){
        API_ENDPOINT = "/api_aam/" + String(aam_member_id) + "/"
      }
      const { data } = await axios.get(API_ENDPOINT);
      const { articles, sources, countries, usa_states, ownership } = cleanArticles(data);
      
      // deleting country 233: United States of America
      delete countries['233'];
      
      this.setState({chartLoading: true, sources: sources, articles: articles, countries: countries, states: usa_states, ownership: ownership});
      this.chart = new Chart(articles, sources, true, this.handleClickContextMenu);
      this.chart.create();
    }
    catch (err) {
      console.log("Data Error! : ",err);
    }
  }

  componentDidMount() {
    this.callAPI();
  }

  formSubmit(values) {
    this.setState(
      {
        formValues: values,
      },
      () => this.drawChart()
    );
  }

  drawChart() {
    ChartUtils.draw(this.chart, this.state);
  }

  chartReset() {
    this.setState({ removedSources: []});
    this.chart.reset();
  }

  dataExport() {
    ChartUtils.exportFilteredSources(this.state);
  }

  handleClickContextMenu = (data) => {
    this.setState({
      showContextMenu: true,
      selectedSource: data,
    });
  }

  handleCloseContextMenu = () => {
    this.setState({ showContextMenu: false });
  }

  handleRemoveSource = () => {
    this.setState({
      removedSources: [...this.state.removedSources, this.state.selectedSource],
    },
    this.drawChart);
  }

  handleResetRemovedSources = () => {
    this.setState({
      removedSources: [],
    });
  }

  handleClickDownload(ob) {
    if(utils.downloadParameter()){
      if(ob === "image"){
        this.download();
      }
      else if(ob === "data"){
        this.dataExport();
      }
    }
    else{
      this.setModalVisible("general_modal",true);
    }
  }

  handleZoom(type) {
    this.chart.clickZoom(type);
  }

  async download() {
    this.setState({ isLoading: true });

    var canvas = document.createElement("canvas");
    canvas.style = "display: none";
    canvas.width = width * 2;
    canvas.height = height * 2;
    document.body.appendChild(canvas);
    let ctx = canvas.getContext("2d");
    ctx.fillStyle = "#fff";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    let svg = d3.select(".chart").node(),
      serializer = new XMLSerializer(),
      svgStr = serializer.serializeToString(svg);

    let v = await canvg.Canvg.fromString(ctx, svgStr, {
      anonymousCrossOrigin: true,
      ignoreClear: true,
    });
    await v.render();

    var lnk = document.createElement("a");
    lnk.download = `chart_${new Date().toLocaleString()}.png`;
    lnk.href = canvas.toDataURL("image/png;base64");
    lnk.click();

    canvas.remove();
    this.setState({ isLoading: false });

  }

  setModalVisible(modal_id, value) {
    if (modal_id === "general_modal"){
      this.setState({showGeneralModal: value});
    }
  }

  render() {
    const { chartLoading, isLoading, sources, countries, states, articles, removedSources, ownership } = this.state;
    return (
      <div className="main">
        <div className="mainContainer">
          <div style={{paddingTop: "1.5em"}}>
          </div>
          <div className="container">
            <div className="controlsContainer shadow">
              <div className="controlsHeader">Controls </div>
              <div>
                <ControlsForm
                  className="controls"
                  formSubmit={this.formSubmit.bind(this)}
                  chartReset={this.chartReset.bind(this)}
                  setModalVisible={this.setModalVisible.bind(this)}
                  countries={countries}
                  states={states}
                  sources={sources}
                  removedSources={removedSources}
                  resetRemovedSources={this.handleResetRemovedSources}
                  articles={articles}
                  ownership={ownership}
                  chartLoading={chartLoading}
                />
                
                  <Tooltip
                  title="Download Customized Chart Image"
                  color="geekblue"
                  placement="right"
                  >
                    <Button
                    size="large"
                    icon={<DownloadOutlined />}
                    className="downloadButton"
                    onClick={() => this.handleClickDownload("image")}
                    loading={isLoading}
                    disabled={!chartLoading}
                    >
                      Image
                    </Button>
                  </Tooltip>
                
                  <Tooltip
                  title="Download Customized CSV Files of Included & Excluded Lists"
                  color="geekblue"
                  placement="right"
                  >
                    <Button
                      size="large"
                      icon={<DownloadOutlined />}
                      className="downloadButton"
                      onClick={() => this.handleClickDownload("data")}
                      loading={isLoading}
                      disabled={!chartLoading}
                    >
                      <span>Data</span>
                    </Button>
                  </Tooltip>
              </div>
            </div>
            <div className="chart shadow">
              <Spin indicator={antIcon} spinning={!this.state.chartLoading}/>
              <div className="chartContainer">
                <ContextMenu
                  show={this.state.showContextMenu}
                  onClose={this.handleCloseContextMenu}
                  onRemove={this.handleRemoveSource}
                />
              </div>
            </div>

            <Modal
              width={800}
              visible={this.state.showGeneralModal}
              onCancel={() => this.setModalVisible("general_modal",false)}
              footer={null}
            >
              <HubspotForm
              portalId={process.env.REACT_APP_HUBSPOT_PORTALID}
              formId={process.env.REACT_APP_HUBSPOT_FORMID}
              onSubmit={() => console.log('Submit!')}
              onReady={(form) => console.log('Form ready!')}
              loading={<div>Loading...</div>}
              />
              {/* <div dangerouslySetInnerHTML={{ __html: freeReachModal }}/> */}
            </Modal>

          </div>
        </div>
      </div>
    );
  }
}

export default App;
